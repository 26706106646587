.content {
  background-color: #111193;
  height: 52px;
  width: 100%;
}

#logo {
  height: 52px;
  width: 52px;
  float: left;
}

.navControls {
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 52px;
  justify-content: space-between;
  padding: 0px 12px;
}

.timezone {
  width: 300px;
  z-index: 1000;
}

.login {
  height: 32px;
}