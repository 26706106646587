.content {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 0px;
}

.title {
  font-weight: bold;
  font-size: 16px;
  padding: 0px;
  margin: 0px;
}

.subtitle {
  font-weight: normal;
  font-size: 12px;
  color: gray;
  padding: 0px;
  margin: 0px;
}

.selected > .subtitle {
  color: white !important;
}