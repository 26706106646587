.content {
  display: flex;
  flex-direction: row;
  justify-content: center;
  height: vh;
  width: 100%;
}

#sidenav {
  width: 30%;
  height: vh;
}

#details {
  width: 70%;
  height: vh;
}