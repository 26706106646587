.content {
  display: flex;
  flex-direction: row;
  justify-content: center;
  justify-content: flex-start;
}

.key {
  font-weight: bold;
  font-size: 17;
  margin-right: 8px;
}

.value {
  font-weight: normal;
  font-size: 17;
}