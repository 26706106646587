.content {
  padding-right: 24px;
}

.link {
  color: blue;
  cursor: pointer;
}

.footer {
  align: center;
}

.emptyLink {
  color: red;
}

.sensorTable {
  margin: 0px auto;
}