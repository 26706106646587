.srmrow {

}

#filterSrms {
  width: 80%;
  height: 32px;
  margin: 12px;
  padding: 0px 6px;
}

.selectedRow {
  background-color: #111193 !important;
}